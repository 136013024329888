<script setup lang="ts">


import {RiftButton, routerPage} from "~/shared";
import {useRoute} from "#app";
const localePath = useLocalePath();

const route = useRoute()
const tripId = computed(() => route?.params?.id as string)
</script>

<template>
  <div :class="$style['trip-setting']">
    <rift-button
      type="submit"
      size="sm"
      tag="nuxt-link"
      :to="localePath({
        name: routerPage.TRIP.name,
        params: routerPage.SETTING.params({ id: tripId })
      })"
      prepend-icon="arrow-left"
    >
      <span> Вернуться к трипу</span>
    </rift-button>

    <nav>
      <ul :class="$style['trip-setting--navigation']">
        <li>
          <nuxt-link
            :to="localePath({
              name: routerPage.SETTING_APPLICATIONS.name,
              params: routerPage.SETTING.params({ id: tripId })
            })"
            :active-class="$style['active-link']"
          >
            Заявки
          </nuxt-link>
        </li>
        <li>
          <nuxt-link
            :to="localePath({
              name: routerPage.SETTING.name,
              params: routerPage.SETTING.params({ id: tripId })
            })"
            :active-class="$style['active-link']"
          >
            Настройки
          </nuxt-link>
        </li>
      </ul>
    </nav>
  </div>
</template>

<style lang="postcss" module>
.trip-setting {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.trip-setting--navigation {
  display: flex;
  gap: 10px;
}

.active-link {
  color: var(--color-blue-normal);
}
</style>
